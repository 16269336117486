<template>
    <div>
        <el-card shadow="hover" class="card">
            <el-form :inline="true" size="small">
                <el-form-item :label="$t('m.coupon.youhuimingcheng')">
                    <el-input v-model="FormData.keyword" :placeholder="$t('m.coupon.youhuimingcheng')">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="pageNumChanged(1)">{{$t("m.coupon.CheckFor")}}</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="Result.list" class="eltable">
                <el-table-column :label="$t('m.coupon.youhuijiangmingcheng')"
                                 prop="couponName"
                                 align="center">

                </el-table-column>
                <el-table-column :label="$t('m.coupon.shiyongyonghu')"
                                 prop="merchantName"
                                 align="center">
                </el-table-column>
                <el-table-column :label="$t('m.coupon.hexiaoshijian')"
                                 prop="useTime"
                                 align="center">
                </el-table-column>
            </el-table>
            <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                <el-pagination
                        background
                        :pageSize="pageSize"
                        :current-page.sync="pageNum"
                        @current-change="pageNumChanged"
                        layout="total,prev, pager, next"
                        :total="Result.total">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {getCouponUseRecordPage} from "request/api/coupon.js"

    export default {
        name: "CouponHistoryList",
        data(){
            return {
                FormData:{
                    keyword:'',
                },
                StoreList:[],
                pageSize:10,
                pageNum:1,
                Result:{}
            }
        },
        mounted() {
            this.getList();
        },
        methods:{

            getList(){
                let data = {
                    pageNum:this.pageNum,
                    pageSize:this.pageSize,
                    merchantName:this.FormData.keyword,
                };

                let _this = this;
                getCouponUseRecordPage(data).then(res=>{
                    if(res.code==200){
                        _this.Result = res.data;
                    }
                })
            },

            //切换页数
            pageNumChanged(page){
                this.pageNum = page;
                this.getList();
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    .eltable{
        margin-top: 20px;
    }
    .card{
        margin: 0 0 10px;
        border: none;
    }

    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
        margin-bottom: 0;
    }

    .img-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .img{
        margin-right: 10px;
        margin-bottom: 10px;
        width: 40px;
        height: 40px;
    }


</style>
